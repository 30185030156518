<template>
  <div>
    <Modal
      v-model="showPreviewModal"
      title="Preview da Prova"
      :enableClose="true"
    >
      <ProvaPreview
        :prova="provaPreview"
        :assuntos="this.assuntos[0]"
        :anos="this.anos[0]"
        :turmas="this.turmas[0]"
        :disciplinas="this.disciplinas[0]"
        :questoes="this.prova.questoes"
      >
      </ProvaPreview>
    </Modal>
    <Modal
      v-model="showModalEscolhaOrigemQuestao"
      title="Adicionar questão"
      :enableClose="true"
    >
      <div
        class="row"
        style="padding-top: 40px; padding-bottom: 40px; height: 200px"
      >
        <div class="col-md-6">
          <button
            :class="{ activeMenu: page == 'adicionarQuestao' }"
            class="btn-block btn btn-light"
            style="
              height: 100%;
              border: 1px solid #ddd;
              color: #333333 !important;
            "
            id="navBancoQuestaoProvaCreate"
            @click.prevent="
              bancoQuestoesEscolha();
              showModalEscolhaOrigemQuestao = false;
            "
          >
            Banco de questões
          </button>
        </div>
        <div class="col-md-6">
          <button
            class="btn-block btn"
            style="height: 100%; border: 1px solid #ddd; color: #333333"
            @click.prevent="
              cadastrarNovaQuestao();
              showModalEscolhaOrigemQuestao = false;
            "
          >
            <div>
              <img src="../../../assets/images/criar-questao.png" width="40%" />
              <br />
              Criar Questão
            </div>
          </button>
        </div>
      </div>
    </Modal>

    <Modal
      v-model="showModalAlunos"
      title="Selecionar Alunos"
      :width="800"
      :adaptive="true"
      :enableClose="true"
    >
      <div class="row">
        <div class="col-md-12">
          <form class="block__form">
            <div class="form-row">
              <div class="col-auto" style="width: 100%">
                <input
                  autocomplete="off"
                  id="search"
                  v-model="filterAlunos"
                  type="search"
                  class="form-control"
                  placeholder="Busque por um nome ou e-mail de aluno"
                  style="padding-left: 40px"
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <hr class="full-hr" />

      <div class="row">
        <div class="col-sm-12">
          <ul class="list-inline m-0">
            <li class="list-inline-item">
              <button
                style="width: 150px"
                class="btn btn-classic"
                @click.prevent="showModalAlunos = false"
              >
                Salvar
              </button>
            </li>

            <li class="list-inline-item">
              <button
                style="width: 150px"
                class="btn btn-outline"
                @click.prevent="showModalAlunos = false"
              >
                Cancelar
              </button>
            </li>
          </ul>
          <br />
          <table
            class="
              block__classic
              table table__classic table-striped table-borderless table-hover
              text-center
              mb-4
            "
          >
            <thead>
              <tr>
                <th width="10%">
                  <input
                    autocomplete="off"
                    type="checkbox"
                    class="form-control"
                    :value="all"
                    style="width: 20px; height: 20px"
                    v-if="!checkAllAlunos"
                    @change="checkAll($event)"
                  />
                  <input
                    autocomplete="off"
                    type="checkbox"
                    class="form-control"
                    :value="all"
                    style="width: 20px; height: 20px"
                    v-if="checkAllAlunos"
                    :checked="true"
                    @change="checkAll($event)"
                  />
                </th>
                <th scope="col" width="60%" style="text-align: left">Nome</th>
                <th scope="col" width="40%" style="text-align: left">E-mail</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in alunos" :key="item.id">
                <td>
                  <input
                    type="checkbox"
                    class="form-control"
                    :value="alunos[index]"
                    style="width: 20px; height: 20px"
                    @change="lookAlunos(alunos[index])"
                    v-if="!checkAllAlunos"
                  />
                  <input
                    type="checkbox"
                    class="form-control"
                    :value="alunos[index]"
                    :checked="true"
                    v-if="checkAllAlunos"
                    style="width: 20px; height: 20px"
                  />
                </td>
                <td
                  style="text-align: left"
                  v-html="highlightMatches(item.nome)"
                />
                <td style="text-align: left" v-html="item.usuario.email" />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Modal>

    <main
      id="content"
      class="main-content page-questions pt-0 bg-oct"
      role="main"
    >
      <div
        class="
          page-questions__header
          d-flex
          justify-content-between
          align-items-center
          div-fixa-menu2
          nav-secondary
        "
        style="color: #fff; height: 70px"
        v-bind:class="{ 'div-fixa-menu2': page == 'adicionarQuestao' }"
      >
        <a
          href=""
          v-if="page == 'adicionarQuestao'"
          :class="{ activeMenu: page == 'provas' }"
          @click.prevent="backProvas()"
          class="link fs-24"
          id="backProvas"
          style="color: #fff"
        >
          <i class="fas fa-chevron-left mr-3" />
          Provas
        </a>

        <router-link
          v-if="this.$route.name == 'questao_create'"
          to="/provas"
          class="link fs-24"
          style="color: #fff"
        >
          <i class="fas fa-chevron-left mr-3" />
          Banco de Questões
        </router-link>

        <router-link
          v-if="
            this.$route.name == 'provas_create' && page != 'adicionarQuestao'
          "
          to="/provas"
          class="link fs-24"
          style="color: #fff"
        >
          <i class="fas fa-chevron-left mr-3" />
          Provas
        </router-link>

        <span style="font-size: 18px">{{ prova.titulo }}</span>

        <ul class="list-inline m-0">
          <li class="list-inline-item" style="padding-right: 10px">
            <a
              href=""
              class="link"
              style="color: #fff; font-size: 18px"
              id="previewProva"
              @click.prevent="preview()"
            >
              <i class="far fa-eye" /> Preview
            </a>
          </li>

          <!--<li class="list-inline-item" style="padding-right: 10px">
            <a
              href=""
              class="link"
              style="color: #fff; font-size: 18px"
              @click.prevent="fluxoStatus('EmRevisao')"
            >
              <i style="color: #fff;"
                ><img :src="require('../../../assets/images/acoes/upload.svg')"
              /></i>
              Enviar para revisão
            </a>
          </li>-->
          <li class="list-inline-item" style="padding-right: 10px">
            <div
              style="
                background-color: white;
                height: 30px;
                color: #10a558;
                border-radius: 5px;
              "
            >
              <a
                href=""
                id="salvarProvaCreate"
                class="color-proj-primary"
                style="
                  font-size: 18px;
                  padding: 10px;
                  margin-top: 11px;
                "
                @click.prevent="save"
              >
                Salvar prova
              </a>
            </div>
          </li>
          <div class="main-nav__profile btn-group ml-auto">
            <button
              type="button"
              class="btn btn__clean dropdown-toggle fs-14 color-oct"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              @click="toggleRightMenu"
            >
              <img
                :src="require('../../../assets/images/avatar.png')"
                alt="Nome do professor"
                class="rounded-circle mr-3"
              />
              {{ user.email }}
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              :class="{ show: showRightMenu }"
            >
              <a class="dropdown-item justify-content-end" href="#"
                >Meu Perfil</a
              >
              <a
                class="dropdown-item justify-content-end"
                href="#"
                @click.prevent="logout"
                >Deslogar/Logout</a
              >
            </div>
          </div>
        </ul>
      </div>
      <div
        class="
          page-questions__header
          d-flex
          justify-content-between
          align-items-center
          div-fixa-menu
        "
        v-bind:class="{ 'div-fixa-menu': page == 'adicionarQuestao' }"
      >
        <div class="row" style="margin: auto">
          <div class="col-md-12">
            <a
              href=""
              style="
                padding: 0 20px;
                border-right: 1px solid #666;
                color: white;
              "
              :class="{ activeMenu: page == 'configuracoes' }"
              @click.prevent="page = 'configuracoes'"
              ><i class="fas fa-cog" /> Configurações
            </a>
            <a
              href=""
              style="
                padding: 0 20px;
                border-right: 1px solid #666;
                color: white;
              "
              :class="{ activeMenu: page == 'provas' }"
              @click.prevent="page = 'provas'"
              ><i><img src="../../../assets/images/peso.svg" /> </i> Prova em
              edição

              <span class="badge" v-if="prova.ordem != 'Aleatória'">
                {{ pesoQuestaoProva }}</span
              >

              <span class="badge bd-2" v-if="prova.ordem == 'Aleatória'"
                >{{ prova.questoes.length }}/{{ prova.qtdQuestoes }}</span
              >
            </a>
            <!-- <a
              style="padding: 0 20px; border-right: 1px solid #666"
              :class="{ activeMenu: page == 'provas' }"
              v-if="prova.ordem == 'Aleatória'"
              ><i class="fas fa-server" /> {{ prova.questoes.length }}/{{
                prova.qtdQuestoes
              }}</a
            > -->
            <a
              href=""
              style="padding: 0 20px; color: white"
              @click.prevent="abrirModalEscolhaOrigemQuestao()"
              ><i class="fas fa-plus" /> Adicionar Questão</a
            >
          </div>
        </div>
      </div>
      <br /><br /><br /><br /><br /><br />

      <!-- Configurações -->
      <div v-if="page == 'configuracoes'" style="padding: 40px">
        <h4 style="color: #d2d2d2"><i class="fas fa-cog" /> Configurações</h4>

        <div style="padding-top: 30px" class="row">
          <div class="col-lg-5 col-md-8">
            <div class="form-group">
              <label for="disciplina"
                >Componente Curricular
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>

              <select
                id="disciplina"
                v-model="prova.disciplina"
                class="form-control"
              >
                <option
                  v-for="item in disciplinas"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.text }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-2 col-md-4">
            <div class="form-group">
              <label for="ano"
                >Ano <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <select
                id="ano"
                v-model="prova.ano"
                class="form-control"
                :disabled="anos.length <= 0"
                @change="onChangeAno()"
              >
                <option v-for="ano in anos" :key="ano.id" :value="ano.id">
                  {{ ano.titulo }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-2 col-md-8">
            <div class="form-group">
              <label for="turma"
                >Turma <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <select
                id="turma"
                v-model="prova.turma"
                class="form-control"
                :disabled="turmas.length == 0"
                @change="onChangeTurma()"
              >
                <option
                  v-for="turma in turmas"
                  :key="turma.id"
                  :value="turma.id"
                >
                  {{ turma.titulo }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-3 col-md-4">
            <div class="form-group" style="padding-top: 30px">
              <div class="alunos">
                <h4 style="font-size: 14px; text-align: center; padding-top: 13px">
                  <b>{{ prova.alunos.length | strpad }}</b> alunos selecionados
                </h4>
              </div>
              <div style="font-size: 12px; text-align: right">
                <a href="" class="link" @click.prevent="listarAlunos()">
                  <img src="../../../assets/images/acoes/editar.svg" />Editar Alunos
                </a>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div style="padding-top: 30px" class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="titulo"
                >Título da Prova
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <input
                id="titulo"
                v-model="prova.titulo"
                type="text"
                class="form-control"
                autocomplete="off"
              />
            </div>
          </div>

          <!-- <div class="col-md-3">
            <div class="form-group">
              <label for="ordem"
                >Ordem da prova
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <select id="ordem" v-model="prova.ordem" class="form-control">
                <option
                  v-for="ordem in ordensProva"
                  :key="ordem"
                  :value="ordem"
                >
                  {{ ordem }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-3" v-if="prova.ordem == 'Aleatória'">
            <div class="form-group">
              <label for="peso"
                >Peso da Prova
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <input
                autocomplete="off"
                id="peso"
                v-model="prova.peso"
                type="number"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-3" v-if="prova.ordem == 'Aleatória'">
            <div class="form-group">
              <label for="numQuestoes"
                >Número de questões
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <input
                autocomplete="off"
                id="numQuestoes"
                v-model="prova.qtdQuestoes"
                type="number"
                class="form-control"
              />
            </div>
          </div> -->
          <div class="col-md-3">
            <div class="form-group">
              <label for="tempoExecucao"
                >Tempo de execução
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <input
                id="tempoExecucao"
                v-model="prova.tempoExecucao"
                type="number"
                min="1"
                class="form-control"
                style="width: 160px"
              />
              <span style="font-size: 12px; font-style: italic; color: #333333"
                >Em minutos</span
              >
            </div>
          </div>

        </div>
        <div class="row" style="padding-top: 30px;">

          <div class="col-lg-4 col-md-5">
            <div class="form-group" style="padding-top: 30px">
              <label
                >Data Hora
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <vc-date-picker
                v-model="prova.dataHora"
                :color="corEmpresa"
                :value="null"
                mode="dateTime"
                is-expanded
                :is24hr="true"
              />
            </div>
          </div>

          <div class="col-lg-4 col-md-3">
            <div class="form-group" style="padding-top: 30px">
              <label for="descricao"
                >Descrição da Prova
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <textarea
                id="descricao"
                v-model="prova.descricao"
                rows="13"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group" style="padding-top: 30px">
              <label for="instrucoes"
                >Instruções da Prova
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <textarea
                id="instrucoes"
                v-model="prova.instrucoes"
                rows="13"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Prova -->
      <div v-if="page == 'provas'" style="padding: 40px; padding-top: 0!important;">
        <h4 style="color: #d2d2d2"><i class="fas fa-list" /> Prova</h4>

        <p v-if="prova.questoes.length <= 0" style="text-align: center">
          <a
            href=""
            class="btn btn-classic"
            id="modalEscolhaOrigem"
            @click.prevent="abrirModalEscolhaOrigemQuestao()"
            ><i class="fas fa-plus" /> Adicionar Questão</a
          >
        </p>

        <div>
          <questao-list
            v-for="questao in prova.questoes"
            :key="questao.questao.id"
            :questao-prop="questao.questao"
            :ordemProva="prova.ordem"
            :is-list-prova="false"
            :is-list-inserida-prova="true"
            :minhaQuestao="true"
          />
        </div>
      </div>

      <!-- Adicionar Questão -->
      <div v-if="page == 'adicionarQuestaoBanco'">
        <br />
        <div class="page-questions__content">
          <div class="content-left">
            <form class="block__form has-tooltip">
              <div class="form-group">
                <label
                  >Origem
                  <i
                    class="help ml-1"
                    data-toggle="tooltip"
                    title="Some tooltip text!"
                /></label>

                <div
                  class="custom-control custom-radio custom-control-inline pl-3"
                >
                  <input
                    id="questoes-banco"
                    type="radio"
                    v-model="filtro.origem"
                    value="todas"
                    name="origem"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label small" for="questoes-banco"
                    >Todas</label
                  >
                </div>

                <div
                  class="custom-control custom-radio custom-control-inline pl-3"
                >
                  <input
                    id="favoritas"
                    type="radio"
                    name="origem"
                    value="favoritas"
                    v-model="filtro.origem"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label small" for="favoritas"
                    >Favoritas</label
                  >
                </div>

                <div
                  class="custom-control custom-radio custom-control-inline pl-3"
                >
                  <input
                    id="criadas"
                    type="radio"
                    name="origem"
                    value="minhas"
                    v-model="filtro.origem"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label small" for="criadas"
                    >Criadas por mim</label
                  >
                </div>
              </div>

              <div class="form-group full-select">
                <label for="habilidade"
                  >Habilidade
                  <i
                    class="help ml-1"
                    data-toggle="tooltip"
                    title="Some tooltip text!"
                /></label>
                <select
                  id="ano"
                  v-model="filtro.assuntosSelecionados"
                  class="form-control"
                >
                  <option
                    v-for="data in assuntosDisponiveis"
                    :key="data.id"
                    :value="data.id"
                  >
                    {{ data.text }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label
                  >Tipo
                  <i
                    class="help ml-1"
                    data-toggle="tooltip"
                    title="Some tooltip text!"
                /></label>
                <div
                  class="custom-control custom-radio custom-control-inline pl-3"
                >
                  <input
                    id="objetiva"
                    type="radio"
                    v-model="filtro.tipo"
                    value="Objetiva"
                    name="tipo"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label small" for="objetiva"
                    >Objetiva</label
                  >
                </div>
                <div
                  class="custom-control custom-radio custom-control-inline pl-3"
                >
                  <input
                    id="descritiva"
                    type="radio"
                    v-model="filtro.tipo"
                    value="Descritiva"
                    name="tipo"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label small" for="descritiva"
                    >Descritiva</label
                  >
                </div>
                <div
                  class="custom-control custom-radio custom-control-inline pl-3"
                >
                  <input
                    id="todas"
                    type="radio"
                    v-model="filtro.tipo"
                    value="todas"
                    name="tipo"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label small" for="todas"
                    >Todas</label
                  >
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-md-12">
                    <button
                      class="btn btn-classic btn-block"
                      @click.prevent="filtrar"
                    >
                      <i class="fas fa-search" /> Filtrar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="content-right px-4">
            <questao-list
              v-for="questao in questoesDisponiveis"
              :key="questao.id"
              :questao-prop="questao"
              :is-list-prova="true"
              :is-list-inserida-prova="false"
              :emailCriador="questao.criadaPor.email"
            />
          </div>
        </div>
      </div>

      <div style="padding: 40px" v-if="page == 'preview'">
        <prova-preview
          :prova="this.prova"
          :assuntos="this.assuntos[0]"
          :anos="this.anos[0]"
          :turmas="this.turmas[0]"
          :disciplinas="this.disciplinas[0]"
          :questoes="this.prova.questoes"
        />
      </div>
      <!--Fim -->

      <div v-if="page == 'criandoNovaQuestao'">
        <questao-create
          :is-cadastra-dentro-prova="true"
          :disciplinaProva="prova.disciplina"
          ref="questaoCreateRef"
        />
      </div>
    </main>
  </div>
</template>

<script>
import Vue from "vue";
import Swal from "sweetalert2";
import VCalendar from "v-calendar";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import Select2 from "v-select2-component";
import QuestaoList from "@/components/questoes/QuestaoList";
import QuestaoCreate from "@/modules/questoes/pages/QuestaoCreate";
import store from "@/store";
import { mapState } from "vuex";
import { constantes } from "@/constants";
import authService from "@/modules/auth/services";

import ProvaPreview from "@/modules/provas/pages/ProvaPreview";
// import QuestaoFilter from "@/components/questoes/QuestaoFilter";
// import PreviewQuestoes from "@/modules/provas/pages/PreviewQuestoes";
import { checkUserHaveRole } from "@/tools";
Vue.use(VCalendar, {
  componentPrefix: "vc",
  QuestaoCreate,
  Select2,
});

export default {
  components: {
    QuestaoCreate,
    Modal: VueModal,
    // Select2,
    QuestaoList,
    ProvaPreview,
    // QuestaoFilter, // PreviewQuestoes,
  },
  data() {
    return {
      page: "configuracoes",
      user: [],
      anoSelecionado: null,
      all: false,
      idSalvo: null,
      showPreviewModal: false,
      provaPreview: Object,
      pesoQuestaoProva: 0,
      pesoProva: false,
      showRightMenu: false,
      ordensProva: ["Sequencial"],
      showModalEscolhaOrigemQuestao: false,
      questoesDisponiveis: [],
      hoje: new Date(),
      assuntos: [],
      assuntoSelecionado: "",
      tipoQuestao: "",
      nivelQuestao: "",
      alunos: [],
      anos: [],
      turmas: [],
      faceis: null,
      medias: null,
      dificeis: null,
      disciplinas: [],
      questoesFiltrar: [],
      checkAllAlunos: false,
      showModalAlunos: false,
      filterAlunos: "",
      showLeftMenu: true,
      hasFilter: this.$route.params.filter ? this.$route.params.filter : false,
      questaoProva: {
        idQuestao: "",
        valor: 0,
      },
      prova: {
        disciplina: "",
        alunos: [],
        questoes: [],
        ano: "",
        turma: "",
        titulo: "",
        peso: 0,
        qtdQuestoes: "",
        descricao: "",
        instrucoes: "",
        dataHora: "",
        tempoExecucao: "",
        ordem: "Sequencial"
      },
      filtro: {
        page: 1,
        size: 1,
        disciplinaSelecionada: null,
        assuntosSelecionados: null,
        anoSelecionado: null,
        tipo: "",
        search: "",
        origem: "",
      },
      disciplinasDisponiveis: [],
      assuntosDisponiveis: [],
      questoesEdicoes: [],
      anosDisponiveis: [],
      questaoFilterList: [
        {
          id: 0,
          isCorreta: false,
          questao: "",
          texto: "",
        },
      ],
    };
  },

  computed: {
    filteredAlunosRows() {
      return this.prova.alunos.filter((row) => {
        const searchTerm = this.filterAlunos.toLowerCase();
        const text = row.nome.toLowerCase();
        return text.includes(searchTerm);
      });
    },
    corEmpresa() {
      debugger;
      if(constantes.EMPRESA === 'veloz'){
        return 'blue';
      }else if(constantes.EMPRESA === 'uniavan'){
        return 'green';
      }
      return '';
    },
    // ...mapState("auth", ["user"]),
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.page = "configuracoes";
    this.loadDisciplinas();
    this.loadAnos();
    this.loadUser();
  },
  methods: {
    somaPeso(val, questaoEditada) {
      val = parseFloat(val);
      if (!val) val = 0;
      if (this.questoesEdicoes.includes(questaoEditada)) {
        let i = this.questoesEdicoes.findIndex(
          (x) => x.id == questaoEditada.id
        );
        this.questoesEdicoes[i].peso = parseFloat(val);
      } else {
        questaoEditada.peso = parseFloat(val);
        console.log(questaoEditada);
        this.questoesEdicoes.push(questaoEditada);
      }

      let valueSum = 0;
      this.questoesEdicoes.forEach((q) => {
        valueSum += q.peso;
      });
      console.log(valueSum);
      this.pesoQuestaoProva = valueSum;

      this.prova.peso = valueSum;
    },
    toggleLeftMenu() {
      this.$root.$emit("Dashboard::toggleLeftMenu");
    },
    toggleRightMenu() {
      this.showRightMenu = !this.showRightMenu;
    },
    loadUser() {
      this.$api
        .get("users/info")
        .then((response) => {
          console.log("eu", response.data);
          this.user = response.data;
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
    logout() {
      authService.logout();
      document.location.reload();
    },
    depoisCriarQuestaoNaProva(prova) {
      this.insereQuestao(prova);
      this.page = 'criandoNovaQuestao';
      this.$refs.questaoCreateRef.resetWindow();
    },
    abrirModalEscolhaOrigemQuestao() {
      this.showModalEscolhaOrigemQuestao = true;
    },
    cadastrarNovaQuestao() {
      console.log(this.prova.disciplina);
      if (this.prova.disciplina != "") {
        this.page = "criandoNovaQuestao";
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Selecione um componente curricular antes</span>',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    voltaParaListaQuestoes() {
      this.loadQuestoes();
      this.page = "adicionarQuestao";
    },
    deletaQuestao(questao) {
      const prova = this.prova;
      this.prova.questoes.forEach(function (value, i) {
        if (value.questao.id == questao.id) {
          prova.questoes.splice(i, 1);
        }
      });
    },
    insereQuestao(questao) {
      var findQuestao = this.prova.questoes.find(
        (element) => element.questao.id == questao.id
      );

      if (findQuestao) {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Essa questão já foi inserida nesta prova!</span>',
          showConfirmButton: false,
          timer: 2500,
        });

        return;
      }
      
      if (this.prova.ordem == "Aleatória") {
        // if (this.prova.questoes.length <= this.prova.qtdQuestoes) {
        var questaoComPeso = {
          questao: questao,
          obrigatoria: false,
        };

        this.prova.questoes.unshift(questaoComPeso);

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Questão adicionada com sucesso na prova!",
          showConfirmButton: false,
          timer: 1500,
        });

        if (
          this.$route.name == "questao-create" ||
          this.$route.name == "questao-edit"
        ) {
          this.page = "questoes";
        }
      } else {
        questao.peso = 1;
        var questaoComPeso2 = {
          questao: questao,
          obrigatoria: false,
        };

        this.somaPeso(parseFloat(questao.peso), questao);
        this.prova.questoes.unshift(questaoComPeso2);

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Questão adicionada com sucesso na prova!",
          showConfirmButton: false,
          timer: 1500,
        });

        if (
          this.$route.name == "questao-create" ||
          this.$route.name == "questao-edit"
        ) {
          this.page = "questoes";
        }
      }
    },
    pad(value) {
      return value.toString().padStart(2, 0);
    },

    validateSave() {


      if (this.prova.ordem === "Aleatória") {
        this.faceis = this.prova.questoes.findIndex(
          (element) => element.questao.nivel == "Fácil"
        );
        this.medias = this.prova.questoes.findIndex(
          (element) => element.questao.nivel == "Médio"
        );
        this.dificeis = this.prova.questoes.findIndex(
          (element) => element.questao.nivel == "Difícil"
        );
        console.log(this.faceis, this.medias, this.dificeis);
        if (!(this.faceis >= 0 && this.medias >= 0 && this.dificeis >= 0)) {
          Swal.fire({
            position: "center",
            icon: "error",
            title:
              '<span style="font-size: 20px;">Oops! É necessário uma questão de cada nível</span>',
            showConfirmButton: false,
            timer: 2500,
          });
          return false;
        }
      }

      if (!this.prova.disciplina || this.prova.disciplina == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não informou o componente curricular para a prova.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      if (!this.prova.ano || this.prova.ano == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não informou o ano para a prova.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      if (!this.prova.turma || this.prova.turma == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não informou a turma para a prova.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      if (!this.prova.titulo || this.prova.titulo == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não informou o título para a prova.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      if (!this.prova.tempoExecucao || this.prova.tempoExecucao == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não informou o tempo de execução para a prova.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      if (!this.prova.descricao || this.prova.descricao == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não informou a descrição para a prova.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      if (!this.prova.instrucoes || this.prova.instrucoes == "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! Você não informou as instruções para a prova.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      if (this.prova.dataHora.valueOf() <= new Date().valueOf()) {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! A data da prova não pode ser inferior a data atual</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      if (this.prova.ordem === 'Aleatória' && !this.prova.peso) {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Oops! O campo Peso da Prova é obrigatório para provas de ordem aleatória.</span>',
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      let possuiPesoInvalido = false;
      this.prova.questoes.forEach((questao, index) => {
        if(questao.questao.peso && questao.questao.peso < 0){
          Swal.fire({
            position: "center",
            icon: "error",
            title:
              '<span style="font-size: 20px;">Oops! Algumas questões estão com valores negativos em seu peso, por favor informe o peso correto em valores positivos!</span>',
            showConfirmButton: false,
            timer: 6000,
          });
          possuiPesoInvalido = true;
        }
      });
      if(possuiPesoInvalido){
        return false;
      }
      return true;
    },

    save() {
      this.prova.qtdQuestoes = this.prova.ordem === "Aleatória" ? parseInt(this.prova.qtdQuestoes) : this.prova.questoes.length;
      this.prova.peso = parseFloat(this.prova.peso);
      if(!this.validateSave()){
        return false;
      }
      this.$root.$emit("Spinner::show");

      this.$api
        .post("prova-add", this.prova)
        .then((res) => {
          this.$root.$emit("Spinner::hide");
          console.log(res);
          this.idSalvo = res.data.id;
          // Se for para redirecionar para edição desta prova, pode pegar o this.idSalvo que é o id que foi gerado pra prova atual
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Prova adicionada com sucesso!",
            showConfirmButton: false,
            timer: 1500,
          }).then(this.voltarProvas);
        })
        .catch((error) => {
          console.log(error);
          this.$root.$emit("Spinner::hide");
          if (error.response.status == 400) {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.detail +
                "</span>",
              showConfirmButton: false,
              timer: 3500,
            }).then(() => {
              this.loadData();
              this.showModalCreate = false;
            });
          } else if (error.response.status == 401) {
            store.dispatch("auth/ActionSignOut");
            window._Vue.$router.push({ name: "login" });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.detail +
                "</span>",
              showConfirmButton: false,
              timer: 2500,
            });
          }
        });
    },
    loadQuestoes() {
      this.$root.$emit("Spinner::show");
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      this.filtro.disciplinaSelecionada = this.prova.disciplina;
      this.loadAssuntos(this.prova.disciplina);

      this.filtro.size = 990;
      this.page = this.filtro.page;
      this.$api
        .post("/questoes-paginacao", this.filtro)
        .then((response) => {
          this.total = response.data.totalItens;
          this.totalPaginas = response.data.paginas;
          this.questoesDisponiveis = [];

          response.data.data.forEach((res) => {
            if (idInstituicao == 0 || res.trashAt === null) {
              this.questoesDisponiveis.push(res);
            }
          });
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          this.$root.$emit("Spinner::hide");

          if (error.response.status == 403) {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">Oops! Você não tem permissão para executar esta ação.</span>',
              showConfirmButton: false,
              timer: 2500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.violations[0].message +
                "</span>",
              showConfirmButton: false,
              timer: 2500,
            });
          }
          this.$root.$emit("Spinner::hide");
        });
    },
    filtrar() {
      this.questoesDisponiveis = [];

      if (this.filtro.disciplinaSelecionada == undefined) {
        this.filtro.disciplinaSelecionada = null;
      }

      if (this.filtro.tipo == "todas") {
        this.filtro.tipo = "";
      }

      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      this.$root.$emit("Spinner::show");
      this.filtro.size = "10";
      this.$api
        .post("/questoes-paginacao", this.filtro)
        .then((response) => {
          if (idInstituicao == 0) {
            this.questoesDisponiveis = response.data.data;
          } else {
            response.data.data.forEach((res) => {
              if (res.trashAt === null) {
                this.questoesDisponiveis.push(res);
              }
            });
          }
          this.total = this.questoesDisponiveis.length;
          this.totalPaginas = response.data.paginas;
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          this.$root.$emit("Spinner::hide");

          if (error.response.status == 403) {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">Oops! Você não tem permissão para executar esta ação.</span>',
              showConfirmButton: false,
              timer: 2500,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title:
                '<span style="font-size: 20px;">' +
                error.response.data.violations[0].message +
                "</span>",
              showConfirmButton: false,
              timer: 2500,
            });
          }
          this.$root.$emit("Spinner::hide");
        });
    },
    changeAno(ano) {
      this.anoSelecionado = ano;
      console.log(this.anoSelecionado);
    },
    loadAssuntos(id) {
      const data = {};
      this.$api.post("assuntos-disciplina/" + id, data).then((response) => {
        if (
          localStorage.getItem(constantes.STORAGE_UNIAVAN_ID_INSTITUICAO) == 0
        ) {
          response.data.forEach((res) => {
            if (res.instituicao == null) {
              this.assuntosDisponiveis.push(res);
              this.assuntos.push(res);
            }
          });
        } else {
          this.assuntosDisponiveis = response.data;
          this.assuntos = response.data;
        }
        this.$root.$emit("Spinner::hide");
      });
    },
    onChangeAno() {
      console.log(this.prova.ano);
      this.turmas = [];
      this.loadTurmas();
    },
    onChangeTurma() {
      this.alunos = [];
      this.prova.alunos = [];
      this.$api.get("alunos-turma/" + this.prova.turma).then((response) => {
        console.log(response.data);
        this.alunos = response.data;
        // this.prova.alunos = response.data;
        this.checkAllAlunos = true;
        this.alunos.forEach((data) => {
          this.prova.alunos.push(data);
        });
      });
    },
    loadTurmas() {
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      this.$root.$emit("Spinner::show");
      let turmasPorAno = [];
      this.$api.get("turma_ensinos").then((response) => {
        response.data.forEach((res) => {
          if (res.anoEnsino.id == this.prova.ano) {
            if (res.trashAt == null) {
              if (res.instituicao == null || res.instituicao.id == idInstituicao)
                turmasPorAno.push(res);
            }
          }
        });
        this.turmas = turmasPorAno;
        this.$root.$emit("Spinner::hide");
      });
    },
    bancoQuestoesEscolha() {
      if (this.prova.disciplina != "") {
        this.loadQuestoes();
        this.page = "adicionarQuestaoBanco";
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title:
            '<span style="font-size: 20px;">Selecione um componente curricular antes</span>',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    lookAlunos(aluno) {
      if (this.prova.alunos.includes(aluno)) {
        let i = this.prova.alunos.findIndex((x) => x.id == aluno.id);
        this.prova.alunos.splice(i, 1);
      } else {
        this.prova.alunos.push(aluno);
      }
      console.log(this.alunos);
    },
    highlightMatches(text) {
      if(!this.filter || !this.filter.toLowerCase()){
        return text;
      }
      const matchExists = text
        .toLowerCase()
        .includes(this.filterAlunos.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filterAlunos, "ig");
      return text.replace(
        re,
        (matchedText) =>
          `<strong style="color: #26A54E !important;">${matchedText}</strong>`
      );
    },
    listarAlunos() {
      this.showModalAlunos = true;
    },
    loadDisciplinas() {
      this.$root.$emit("Spinner::show");

      this.$api.get("disciplinas").then((response) => {
        // this.disciplinas = response.data;
        // this.disciplinasDisponiveis = response.data;
        response.data.forEach((res) => {
          if (res.trashAt == null) {
            this.disciplinas.push(res);
            this.disciplinasDisponiveis.push(res);
          }
        });
        this.$root.$emit("Spinner::hide");
      });
    },
    fluxoStatus(status) {
      this.$api
        .post("/provas-fluxo-status/" + this.prova, {
          status: status,
          prova: this.prova.id,
        })
        .then((response) => {
          console.log(response);
          this.$root.$emit("Spinner::hide");

          Swal.fire({
            icon: "success",
            title: "Prova " + status + "!",
            showConfirmButton: false,
            timer: 1500,
            position: "center",
          }).then(() => {
            location.reload();
          });
          setTimeout(function () {
            location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    backProvas() {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Você deseja sair?",
        text: "Ao sair você perde todo seu progresso",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Sair",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      }).then((res) => {
        if (res.isConfirmed) {
          this.voltarProvas();
        }
      });
    },
    voltarProvas() {
        this.$router.push({
          name: "provas",
      });
    },
    checkAll(e) {
      console.log(e);
      if (e.target.checked == true) {
        this.checkAllAlunos = true;
        this.alunos.forEach((data) => {
          this.prova.alunos.push(data);
        });
      } else {
        this.checkAllAlunos = false;
        this.prova.alunos = [];
      }
      console.log(this.prova.alunos);
    },
    preview() {
      this.provaPreview = this.prova;
      console.log(this.provaPreview);
      this.showPreviewModal = true;
      // this.page = 'preview';
    },
    loadAnos() {
      this.$root.$emit("Spinner::show");
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      let anosInstituicao = [];
      this.$api.get("ano_ensinos").then((response) => {
        this.anosDisponiveis = response.data;
        console.log(response);
        if (idInstituicao == 0) {
          this.anos = response.data;
        } else {
          response.data.forEach((res) => {
            if (res.trashAt == null) anosInstituicao.push(res);
          });
          this.anos = anosInstituicao;
          console.log(this.anos);
        }
        // this.anos = response.data;
        this.$root.$emit("Spinner::hide");
      });
    },
  },
};
</script>

<style>
.vm {
  max-width: 500px !important;
  width: 500px !important;
}

.activeMenu {
  border-bottom: 5px solid var(--proj-primary);
  padding-bottom: 10px !important;
}

.badge {
  background-color: white;
  text-align: center;
  color: black;
  font-size: 18px;
}
.div-fixa {
  position: fixed;
  left: 0px;
  top: 100px;
  overflow: hidden;
  padding: 6px;
}
.div-fixa {
  position: fixed;
  left: 0px;
  top: 100px;
  overflow: hidden;
  padding: 6px;
}

.bd-2 {
  font-size: 14px;
  padding-left: 0;
}
.div-fixa-menu {
  position: fixed;
  z-index: 999;
  width: 100%;
  margin-top: 70px;
}
.div-fixa-menu2 {
  position: fixed;
  z-index: 999;
  width: 100%;
}

.menu-fixo {
  position: fixed;
  z-index: 999;
  width: 100%;
}

.alunos {
  background-color: #f6f5f5;
  height: 45px;
  border-radius: 5px;
}
</style>
